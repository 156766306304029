<template>
  <!-- remove || false when active this page -->
  <b-overlay
    :show="show && false"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-card>
      <h3>{{ $t('thisPageIsInactiveForNow-withdraw') }}</h3>
      <b-button
        class="d-flex justify-content-center align-items-center btn-style w-50 mx-auto mt-3"
        variant="info"
        style="height: 100px;"
        :to="`/${$i18n.locale}/crypto-wallets`"
      >
        <h4
          class="text-white"
        >
          {{ $t('crypto Withdraw') }}
        </h4>
      </b-button>
    </b-card>
    <!-- remove v-if="false" when active this page -->
    <b-card
      v-if="false"
      no-body
    >
      <div>
        <b-row>
          <b-col
            md="4"
            class="card-border"
          >
            <b-card-body>
              <div>
                <h2 class="mb-1">
                  {{ $t('fiatWidraw.description') }}
                </h2>
                <p>{{ $t('fiatWidraw.text') }}</p>
                <div class="d-flex justify-content-center mt-3">
                  <b-button
                    variant="primary"

                    :to="`/${$i18n.locale}/document/1`"
                  >

                    {{ $t('fiatWidraw.buttontxt') }}
                  </b-button>
                </div>
              </div>
            </b-card-body>

          </b-col>
          <b-col v-if="currencyNotFound">
            <b-card-body>

              <div
                class="alert alert-warning"
                role="alert"
              >
                <h3 class="alert-heading">
                  {{ $t('yourWalletBalanceIsZero') }}
                </h3>
                <div class="alert-body">
                  {{ $t('WalletBalanceIsZeroDescription') }}
                </div>

              </div>
            </b-card-body>
          </b-col>
          <b-col
            v-if="!currencyNotFound"
            md="8"
          >
            <b-card-body>
              <b-overlay
                :show="balance == null || buyPrice == null"
                spinner-variant="primary"
                spinner-type="grow"
                spinner-small
                rounded="sm"
              >
                <b-alert
                  variant="success"
                  :show="balance != null && buyPrice != null"
                  class="p-2"
                >
                  <div class="d-flex justify-content-between">
                    <h4 class="mb-1">
                      {{ $t('fiatWidraw.totalBalance') }}
                    </h4>
                    <h4
                      class="mb-1"
                      dir="ltr"
                    >
                      {{ helpers.numberWithCommas((balance*buyPrice ))+' '+currency.from_currency }}
                    </h4>
                  </div>
                  <div class="d-flex justify-content-between">
                    <span>{{ $t('convert_rate') }}</span>
                    <span dir="ltr">{{ buyPrice+' '+currency.from_currency }}</span>
                  </div>
                </b-alert>
              </b-overlay>
              <validation-observer ref="simpleRules">
                <b-form class="auth-forgot-password-form mt-2">
                  <b-col>
                    <b-form-group
                      label-for="currency"
                      :label="$t('fiatWidraw.currency')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        vid="currency"
                        :name="$t('fiatWidraw.currency')"
                        rules="required"
                      >
                        <v-select
                          id="currency"
                          v-model="currency"
                          :options="currencies"
                          :placeholder="$t('fiatWidraw.select')"
                          label="from_currency"
                          track-by="name"
                          @input="onchangeCurrency"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      :label="$t('fiatWidraw.amount')"
                      label-for="amount"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('fiatWidraw.amount')"
                        rules="required"
                      >
                        <b-form-input
                          v-model="amount"
                          name="amount"
                          :placeholder="`${$t('fiatWidraw.amount')}...`"
                          autocomplete="nope"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col class="mb-5">
                    <b-form-group
                      label-for="event-importance"
                      :label="$t('widrawModal.iban')"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('widrawModal.iban')"
                        rules="required"
                      >
                        <v-select
                          id="event-importance"
                          v-model="iban"
                          :options="ibans"
                          :placeholder="$t('fiatWidraw.select')"
                          label="iban"
                          track-by="name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-form>
              </validation-observer>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  data-toggle="modal"
                  data-target="#myModal"
                  variant="primary"
                  class="mt-2 btn-block"
                  :disabled="submitLoading"
                  @click="showModal()"
                >

                  <span v-if="!submitLoading">{{ $t('fiatWidraw.save_changes') }}</span>
                  <div v-if="submitLoading">
                    <b-spinner
                      small
                      type="grow"
                    />
                    <span>{{ $t('loading') }}</span>
                  </div>
                </b-button>
                <b-modal
                  id="modal-scoped"
                  centered
                  size="lg"
                >
                  <template #modal-header="{ close }">

                    <feather-icon
                      icon="XIcon"
                      size="16"
                      style="cursor:pointer;"
                      @click="close()"
                    />

                  </template>

                  <template>
                    <b-card>
                      <b-card-body class="card-padding ">
                        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                          <div>
                            <div class="d-flex align-items-center mb-2">

                              <h3 class="text-primary vuexy">
                                {{ $t('invoice.brandName') }}
                              </h3>
                            </div>
                            <h6 class="mb-2">
                              {{ $t('invoice.invoiceTo') }}:
                            </h6>
                            <div
                              class="text-left"
                              dir="ltr"
                              style="text-align:left !important"
                            >
                              <p class="card-text  ">
                                {{ $store.state.auth.user.first_name }} {{
                                  $store.state.auth.user.last_name
                                }}
                              </p>
                              <p class="card-text">
                                {{ $store.state.auth.user.email }}
                              </p>
                              <p class="card-text mb-0">
                                +{{ $store.state.auth.user.mobile_country_code }} {{
                                  $store.state.auth.user.mobile
                                }}
                              </p>
                            </div>
                          </div>
                          <div class="mt-md-0">
                            <h4 class="invoice-title">
                              {{ $t('invoice.fiatInvoice') }}
                            </h4>
                            <div class="d-flex align-items-center mt-3">
                              <p class="invoice-date-title">
                                {{ $t('invoice.dateIssued') + ':' }}
                              </p>
                              &nbsp;
                              <p class="invoice-date">
                                {{ new Date().toUTCString() }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </b-card-body>

                      <hr class="my-2">
                      <b-card-body class="card-padding " />
                      <b-row class="row-bg p-1 bg-success text-white font-weight-bold">
                        <b-col cols="5">
                          <div>{{ $t('invoice.invoiceDetails') }}</div>
                          <div>{{ $t('invoice.withdrawalFromYourWallet') }}</div>
                        </b-col>
                        <b-col cols="7">
                          <b-row class="d-flex justify-content-around ">
                            <b-col>

                              <div>{{ $t('fiatWidraw.amount') }}</div>
                              {{ amount + ' ' + currency.from_currency }}
                            </b-col>

                            <b-col>
                              <div>{{ $t('widrawModal.iban') }}</div>
                              <div>{{ iban.iban }}</div>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <b-card-body>

                        <hr class="my-2">
                        <b-row class="card-border">
                          <b-col
                            md="6"
                            class="d-flex
                            order-md-2
                            order-1"
                          >
                            <div class="invoice-total-wrapper">
                              <div class="invoice-total-item d-flex justify-content-between">
                                <p class="invoice-total-title">
                                  {{ $t('total') }}:
                                </p>
                                &nbsp;
                                <p class="invoice-total-amount">
                                  {{ amount + ' ' + currency.from_currency }}
                                </p>
                              </div>
                            </div>
                          </b-col>
                        </b-row>

                      </b-card-body>
                    </b-card>
                  </template>

                  <template #modal-footer="{ ok }">
                    <b-button
                      size="md"
                      variant="success"
                      @click="submit"
                    >
                      {{ $t('widrawModal.confirm') }}
                    </b-button>
                  </template>
                </b-modal>
              </b-col>
            </b-card-body>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row v-if="alertShow">
      <b-col
        sm="12"
      >
        <b-alert
          variant="success"
          show
          class="px-1"
        >

          <div class="alert-body p-2">
            <h4>
              {{ $t('successful-widrawModal') }}
            </h4>
          </div></b-alert>

      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>

import {
  BCard, BCol, BRow, BCardBody, BFormGroup, BFormInput, BButton, BModal, BOverlay, BForm, BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import DocumentApis from '@/services/apis/document'
import FiatApis from '@/services/apis/fiat'
import CustomerWalletApis from '@/services/apis/customer-wallet'
import { toEnglishDigits, numberWithCommas } from '@/utils/index'
import orderHistorySocket from '@/services/websockets/orderHistorySocket'

const fiat = new FiatApis()
const userDocument = new DocumentApis()
const customerWallet = new CustomerWalletApis()
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BCardBody,
    BFormInput,
    BFormGroup,
    BButton,
    BModal,
    vSelect,
    BOverlay,
    BForm,
    BAlert,
  },
  directives: {
    Ripple,

  },
  data() {
    return {
      show: true,
      iban: '',
      ibans: [],
      currency: '',
      currencies: [],
      amount: '',
      required,
      currencyNotFound: null,
      submitLoading: false,
      alertShow: false,
      balance: null,
      buyPrice: null,
      helpers: {
        numberWithCommas,
      },
    }
  },
  computed: {
    isUserExist() {
      return this.$store.state.auth.user?.id
    },

  },
  watch: {
    isUserExist(val) {
      if (val) {
        this.getBalance()
      }
    },

  },
  mounted() {
    Promise.all([userDocument.getDocumentBankInfo({ status: 'success' }), fiat.getCurrency()]).then(res => {
      const currency = res[1].data.results.form.filter(item => item.from_currency === 'IRR')[0]
      if (!currency) {
        this.currencyNotFound = true
      } else {
        this.currencies = res[1].data.results.form.reverse()
        this.currency = currency
        this.buyPrice = res[1].data.results.prices.find(item => item.from_currency === currency.from_currency)?.buy
      }
      this.ibans = res[0].data.results.filter(item => item.currency?.id === +this.currency.from_currency_id)

      this.show = false
    }).catch(err => {
      this.show = false
      console.log(err)
    })
    if (this.isUserExist) {
      this.getBalance()
    }
  },
  beforeDestroy() {
    orderHistorySocket.removeMessageEvent('getAccountforWithdraw')
    orderHistorySocket.removeMessageEvent('accountforWithdraw')
  },
  methods: {
    // get balance
    getBalance() {
      orderHistorySocket.setVueStore(this.$store)
      orderHistorySocket.connect()
      orderHistorySocket.send('reqAccount', {
        status: [0],
        limit: 10,
        page: 1,
      })
      orderHistorySocket.setMessageEvent(
        'getAccountforWithdraw',
        'account',
        data => {
          this.balance = data.balance_usdt
        // this.show = false
        },
      )
      orderHistorySocket.setMessageEvent(
        'accountforWithdraw',
        'accountChange',
        data => {
          switch (data.type) {
            case 'UPDATE':
              this.balance = data.change.balance_usdt
              break
            default:
              break
          }
        },
      )
    },
    async showModal() {
      const valid = await this.$refs.simpleRules.validate()
      if (valid) {
        this.$bvModal.show('modal-scoped')
      }
    },
    submit() {
      this.$bvModal.hide('modal-scoped')
      this.submitLoading = true
      customerWallet.storeWithdraw({
        currency_id: this.currency.from_currency_id,
        amount: toEnglishDigits(this.amount),
        bank_id: this.iban.id,
      }).then(res => {
        this.$swal({
          title: res.data.message,
          icon: 'success',
          timer: 2500,
          showConfirmButton: false,
        })
        this.alertShow = true
      }).catch(err => {
        console.log(err)
      }).finally(() => {
        this.submitLoading = false
      })
    },
    async onchangeCurrency() {
      this.show = true
      this.iban = ''
      await fiat.getCurrency().then(res => {
        this.buyPrice = res.data.results.prices.find(item => item.from_currency === this.currency.from_currency)?.buy
      }).catch(err => {
        console.log(err)
      })
      await userDocument.getDocumentBankInfo({ status: 'success', currency_id: this.currency.from_currency_id }).then(res => {
        this.ibans = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
  },
}
</script>
<style scoped>
.card-border {
  border-right: 1px solid #e3e1e9;
}

.invoice-border {
  border: 1px solid #92ff92;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">

</style>
<style lang="scss">

</style>
